import getCountry from './int'
import { type } from 'jquery'

const fetchData = async (country) => {
  const {paintings, types} = await import(`./paintings${country}`)
  return {paintings, types}
}

export async function getPaintings(typeId = null) {
  
  const country = getCountry()
  console.log(country)
  const {paintings, types} = await fetchData(country)
  if(!typeId || typeId === 1) return paintings
  // console.log(typeId)
  const type = types.find(t => t.id === typeId)
  return paintings.filter((p) => p.type === type.name)

}

