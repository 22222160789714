import React from 'react';

const Container = (props) => {
    return ( 
        <div className="row">
            <div className="col">
                <div className="container">
                    {props.children}
                </div>
            </div>
        </div>
     );
}
 
export default Container;