import React from "react";
import "./styles.css";
import Lata from "../lata";
import {variables as vars} from "../../utils/variables"

const ProductCard = (props) => {
  const { painting } = props;

  // console.log(vars("countryAbbr"))

  return (
    <div className="cardx">
      {painting.dura && (
        <div className="dura d-md-none">
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/dura/" + painting.dura + ".png"}
            alt="Dura 6 anos"
          />
        </div>
      )}

      <div className="row">
        <div className="col-md-5 d-md-flex align-items-center lata-container px-md-0">
          {!painting.beforeAfter && <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/latas/" + painting.id + ".png"} alt="" />}
          {painting.beforeAfter && <Lata id={painting.id} name={painting.name}></Lata>}
        </div>
        <div className="col-md-7 px-3 info-container">
          <div className="row">
            <div className="col-9 d-md-flex align-items-md-center justify-content-md-center">
              <div>
                <h3>
                  {painting.name}
                </h3>
                <p className="info-bold">{painting.subtitle}</p>
              </div>
              
            </div>
            {painting.dura && (
              <div className="col-3 pl-0 d-none d-md-block">
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/img/"+vars("countryAbbr")+"/dura/" +
                    painting.dura +
                    ".png"
                  }
                  alt="Dura anos"
                />
              </div>
            )}
          </div>
          <ul className="borderlist">
            {painting.properties.map((property) => (
              <li key={property}>{property}</li>
            ))}
          </ul>
          {painting.cores && (
            <div className="cores-container">
              <div>
                <p className="info-p">
                  <span className="info-bold d-block d-md-inline">{vars("card1")}:</span>
                </p>
              </div>
              <div className="cor-items">
              {painting.cores.map((cor, index) => (
                <div className="cor-item" key={index}>
                  <span className={cor.length == 2 ? 'cor-name' : 'cor-name-no-img'}>{cor[0]}</span>
                  {cor.length == 2 &&
                  <img
                    className="img-chip"
                    src={
                      "/img/"+vars("countryAbbr")+"/chips/" +
                      cor[1] +
                      ".jpg"
                    } 
                    alt={cor[0]}
                  />
                  }
                </div>
              ))}
              </div>
            </div>
          )}
          {painting.acabamento && (
            <p className="info-p">
              <span className="info-bold d-block d-md-inline">{vars("card2")}</span>
              <span> {painting.acabamento}</span>
            </p>
          )}

          <p className="info-p">
            <span className="info-bold d-block d-md-inline">{vars("card3")}</span>
            <span> {painting.indicacoes}</span>
          </p>
          {painting.secagem && (
            <p className="info-p">
              <span className="info-bold d-block d-md-inline">{vars("card4")}</span>
              <span> {painting.secagem}</span>
            </p>
          )}

          <p className="info-p">
            <span className="info-bold d-block d-md-inline">{vars("card5")}</span>
            <span> {painting.embalagem}</span>
          </p>
          {painting.nota && 
            <p className="info-p">
              <span> {painting.nota}</span>
            </p>
          }
          {painting.bt && 
            <p className="info-p baixe">
            <a href={process.env.PUBLIC_URL + "/pdfs/"+vars("countryAbbr")+"/" + painting.bt } target="_blank" style={{cursor:'pointer'}}>
              <span className="info-bold d-block d-md-inline">{vars("card6")}</span>
            </a>
          </p>
          }
          {painting.fispq && 
            <p className="info-p baixe">
            <a href={process.env.PUBLIC_URL + "/pdfs/"+vars("countryAbbr")+"/" + painting.fispq } target="_blank" style={{cursor:'pointer'}}>
              <span className="info-bold d-block d-md-inline">{vars("card7")}</span>
            </a>
          </p>
          }

        </div>
      </div>
    </div>
  );
};

export default ProductCard;
