import React, { useState } from "react"
import "./styles.css"
import BeforeAfterSlider from 'react-before-after-slider'
import {variables as vars} from "../../utils/variables"

const Lata = (props) => {

  const {id} = props

  const before = process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/latas/" + id + "V.png"
  const after = process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/latas/" + id + "N.png"
  // console.log(before, after)
  return (
    
    <React.Fragment>
      <div className="d-none d-md-block">
        <BeforeAfterSlider
          before={before}
          after={after}
          width={220}
          height={250}
        />
      </div>
      <div className="d-md-none px-3">
        <BeforeAfterSlider
          before={before}
          after={after}
          width={162}
          height={186}
        />
      </div>
    </React.Fragment>
    
  )
}

export default Lata
