import getCountry from './int'

const fetchData = async (country) => {
  const {types} = await import(`./paintings${country}`)
  return types
}

const getTypes = async () => {
  
  const country = getCountry()
  const res = await fetchData(country)
  // console.log(res)
  return res

}
export {getTypes}

  