import React from "react";
import Container from "../container";
import { variables as vars } from "../../utils/variables";
import "./styles.css";

const BannerTecnologia = () => {
  return (
    <div className="row containerx" id="tecnologia-container">
      <div className="col">
        <Container>
          <div className="row">
            <div className="col-md-3 offset-md-2 d-flex align-items-center">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/img/" +
                  vars("countryAbbr") +
                  "/tecnologia2.png"
                }
                alt="tecnologia"
                className="img-fluid"
                id="tecnologia"
              />
            </div>
            <div className="col-md-7 text-white text-center">
              <p
                className="my-1 text-white font-bold text-uppercase"
                id="nossos"
              >
                {vars("tecno1")}
              </p>
              <h4
                className="font-bold px-3 text-uppercase py-1 bg-light"
                id="h4tecno"
              >
                {vars("tecno2")}
              </h4>
              <p className="my-4 text-white font-bold" id="evolucao">
                {vars("tecno3")}
                <br className="d-none d-md-inline" /> 
                {vars("tecno4")}
                <br className="d-none d-md-inline" /> 
                {vars("tecno5")}
              </p>
              {/* <p className="my-1 text-white font-bold" id="evolucao">A evolução da base da água. </p>
                            <p className="my-1 text-white font-bold mb-2" id="uma">Uma Tecnologia que oferece a mesma durabilidade e<br className="d-none d-md-inline" /> resistência do protetor e verniz tradicional, combinada<br className="d-none d-md-inline" /> com maior praticidade e conveniência.</p> */}
              <ul className="boxlist">
                <li>{vars("tecno6")}</li>
                <li>{vars("tecno7")}</li>
                <li>{vars("tecno8")}</li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BannerTecnologia;
