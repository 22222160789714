import React, { useEffect } from "react";
// import bannerA from "../../img/banner2a.jpg";
// import bannerB from "../../img/banner2b.jpg";
// import bannerC from "../../img/banner2c2.jpg";
// import bannerD from "../../img/banner2d.jpg";
import Container from "../container";
import {variables as vars} from "../../utils/variables"

const BannerSecondary = (props) => {
  const { selectType, goTo, paintingTypes } = props;

  const sendEvent = (section) => {
    // console.log(section)
    props.gaEvent(
      "Banner",
      "Click en " + section,
      section,
    );
  };

  return (
    <React.Fragment>
      <div className="row py-1 px-md-4">
        <div className="col-6 col-md-3 p-1"  onClick={ () => sendEvent(paintingTypes[1].name)}>
          <img
            src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/bannerA.jpg"}
            alt="bannerA"
            className="w-100"
            onClick={() => selectType(paintingTypes[1])}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="col-6 col-md-3 p-1" onClick={ () => sendEvent("Vernizes")}>
          <img
            src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/bannerB.jpg"}
            alt="bannerB"
            className="w-100"
            onClick={() => selectType(paintingTypes[2])}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="col-6 col-md-3 p-1" onClick={ () => sendEvent("Balance")}>
          <img
            src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/bannerC.jpg"}
            alt="bannerC"
            className="w-100"
            onClick={() => goTo("tecnologia-container")}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="col-6 col-md-3 p-1" onClick={ () => sendEvent("Inspiracao")}>
          <a href={vars("cetolTecaHref")}>
            <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/bannerD.jpg"} alt="bannerD" className="w-100" />
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BannerSecondary;
