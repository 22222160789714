import { variables as brVariables } from "./br"
import { variables as chiVariables } from "./chi"
import { variables as bolVariables } from "./bol"

export const variables = (word) => {
    const href = window.location.href
    if(href.includes('com.br') || href.includes('sparlack/br')) {
        return brVariables[word]
    } else if (href.includes('com.bo') || href.includes('sparlack/bol') ) {
        return bolVariables[word]
    }else { 
        return chiVariables[word]
    }

} 