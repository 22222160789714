import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import ProductCard from "../productCard";
import OwlCarousel from "react-owl-carousel2";
import "./owl.css";
import "./owl-theme.css";
import "./styles.css";
// import 'react-owl-carousel2/style.css';
import $ from "jquery";
import {variables as vars} from "../../utils/variables"

const Carousel = (props) => {
  const { paintings, selectedPaintingType } = props;
  const [ showPresentationVernizes, setShowPresentationVernizes ] = useState(true)
  const [ showPresentationProtetores, setShowPresentationProtetores ] = useState(true)

  let interval
  let time = 0
  let lastTitle = ""

  const options = {
    items: 3,
    nav: true,
    navText: ["<", ">"],
    dots: false,
    rewind: true,
    autoplay: false,
    margin: 80,
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    responsive: {
      0: {
        margin: 40,
        mouseDrag: false,
      },
      992: {
        margin: 80,
      },
    },
  };

  const setPresentationToFalse = () => {
    if(selectedPaintingType.id === 3)
        setShowPresentationVernizes(false)
    if(selectedPaintingType.id === 1)
      setShowPresentationProtetores(false)
  }

  // useEffect(() => {

  //   checkClassesFirstTime();
  //   $(".owl-prev, .owl-next").click(function () {
  //     checkClassesFirstTime();
  //     setPresentationToFalse()
  //   });
  // });

  const events = {
    onDragged: function (event) {
      // checkClassesFirstTime();
      // setPresentationToFalse()
    },
    onChanged: function(event) {
      
       let title = $(".secondActiveItem h3").text()
       if(title !== "")
        timer(title)
      
    }
  };

  const timer = (title) => {
    if(time !== 0){
      // console.log(lastTitle, time)
      props.gaEvent(
        "Producto",
        lastTitle,
        time,
      );
      
    }
    time = 0
    lastTitle = title
    clearInterval(interval)
    interval = setInterval(() => {
      time++
      // console.log(time, title)
    }, 1000);
  }
  

  function checkClassesFirstTime() {
    let total = $(".owl-stage .owl-item.active").length;
    // console.log(total);
    $(".owl-stage .owl-item")
      .removeClass("firstActiveItem secondActiveItem lastActiveItem")
      .css("opacity", ".7");

    $(".owl-stage .owl-item.active").each(function (index) {
      if (index === 0) {
        $(this)
          .addClass("firstActiveItem")
          .css({ opacity: ".7", transform: "scale(1, 1)" });
      } else if (index === 1) {
        $(this)
          .addClass("secondActiveItem")
          .css({ opacity: "1", transform: "scale(1.2, 1.2)" });
      } else {
        $(this).addClass("lastActiveItem").css("transform", "scale(1, 1)");
      }
    });
  }

  const renderProducts = (painting, index) => {
    // if (selectedPaintingType.hasPortrait) {
    //   // if (index === 1) {
    //     return (
    //       <React.Fragment key={selectedPaintingType.name+painting.id}>
    //         <ProductCard painting={painting} key={painting.id}></ProductCard>
    //         {
    //           (selectedPaintingType.id == 3 && showPresentationVernizes
    //             ||selectedPaintingType.id == 1 && showPresentationProtetores
    //           ) && 
    //           <img
    //           src={
    //             process.env.PUBLIC_URL +
    //             "/img/"+vars("countryAbbr")+"/types/" +
    //             selectedPaintingType.id +
    //             ".png"
    //           }
    //           alt={selectedPaintingType.name} 
    //         />
    //         }
            
            
    //       </React.Fragment>
    //     );
    // } else {
    //   return <ProductCard painting={painting} key={painting.id}></ProductCard>;
    // }
    return <ProductCard painting={painting} key={painting.id}></ProductCard>;
  };

  return (
    <div className="row house-bg" id="carousel">
      <div className="col">
        <OwlCarousel options={options} events={events}>
          {paintings.map((painting, index) => renderProducts(painting, index))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default Carousel;
