import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "./styles.css";
import {variables as vars} from "../../utils/variables"

class BannerCarousel extends React.Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
    return (
      <div className="row rowCarousel">
        <div className="col">
          <Slider {...settings}>
            <div className="slide">
              <a href={vars("bannerCarouselSlide1Href")}>
                <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/carousel-slide-1.jpg"} alt="" className="d-none d-lg-block" />
                <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/carousel-slide-1-mobile.jpg"} alt="" className="d-lg-none" />
              </a> 
            </div>
            <div className="slide">
              <a href={vars("bannerCarouselSlide2Href")}>
                <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/carousel-slide-2.jpg"} alt="" className="d-none d-lg-block" />
                <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/carousel-slide-2-mobile.jpg"} alt="" className="d-lg-none" />
              </a>
            </div>
        </Slider>
        </div>
      </div>
    );
  }
}

export default BannerCarousel;
