export const variables = {
    countryAbbr: "bol",
    gaId:"UA-141508909-4",
    mainBannerHref: "https://www.sparlackcetol.com.bo/cetolteca/",
    tiendasValue: "Tiendas",
    tiendasHref: "https://stores.sparlackcetol.com.bo/",
    tiendasEvent: "Tiendas",
    tutorialsValue: "Tutoriales",
    tutorialsHref: "https://www.youtube.com/channel/UCl0HrO_u_pK09khXneSwW9A",
    tutorialsEvent: "Tutoriales",
    cetolTecaHref: "https://www.sparlackcetol.com.bo/cetolteca/",
    storesValue: "Dónde comprar",
    storesHref: "https://coralcercatuyo.com/",
    storesEvent: "Dónde comprar",
    showInspiration: false,
    inspiration: "Inspiración",
    theCompany: "La empresa",
    colors: "Colores",
    showColors: false,
    colorsHref: "",
    soon: "Próximamente",
    youWillBeAble: "podrá acceder a las mejores ideas",
    ofInspiration: "de inspiración",
    goBack: "Volver a la HOME",
    fbk: null,
    ig: null,
    email: 'Sparlack.responde@akzonobel.com',
    phone: null,
    phoneText: null,
    faq: "Preguntas frecuentes",
    faqLink: "https://www.sparlackcetol.com.bo/pdfs/bol/Preguntas Frecuentes SPARLACK CETOL.pdf",
    
    woodLives: "LA MADERA VIVE,",
    weTakeCare: "NOSOTROS LA CUIDAMOS",
    text1: "La madera vive, tiene movimiento… Se dilata, se contrae y respira.",
    text2: `Como nuestro cuerpo, sin una piel protectora que la proteja de los agentes externos como el sol y la lluvia, queda expuesta a un envejecimiento prematuro y su deterioro permanente.`,
    text3: `Una vez que separamos la madera de la naturaleza, pierde la protección natural que solo Sparlack Cetol le puede devolver. `,
    text4: `Si reemplazamos la palabra piel por Sparlack Cetol, logramos una definición perfecta de nuestro producto.`,
    text5: `LA PIEL QUE SU MADERA NECESITA`,
    text6: `La nueva línea de protectores Sparlack Cetol posee una tecnología que protege la madera creando una película elástica y microporosa que no se cuartea y no descascara, ofreciendo:`,
    text7: `Máxima duración`,
    text8: `Mayor resistencia a los agentes externos y al paso del tiempo`,
    text9: `La Tecnología Cetol penetra en la madera generando una piel elástica, que acompaña sus movimientos de contracción y dilatación, Impermeable, porque no deja pasar el agua y Microporosa,  porque la deja respirar, brindando una terminación natural que deja al descubierto las vetas de la madera.`,
    tecno1: `Nuestros productos también poseen`,
    tecno2: `TECNOLOGÍA BALANCE`,
    tecno3: `Una tecnología que ofrece la misma durabilidad y`,
    tecno4: `resistencia de un protector y barniz tradicional,`,
    tecno5: `pero combinada con los atributos de practicidad y fácil aplicación.`,
    tecno6: `secado rápido`,
    tecno7: `Bajo olor`,
    tecno8: `Viene listo para usar`,
    video1: `Acceda a nuestro`,
    video2: `contenido exclusivo`,
    video3: `en Youtube`,
    video4: `Ver todo`,
    youtubeLink: "https://www.youtube.com/channel/UCl0HrO_u_pK09khXneSwW9A",
    youtubeIframe: "https://www.youtube-nocookie.com/embed/0zGo-mAlfvk?rel=0",
    card1: `Colores`,
    card2: `Terminación`,
    card3: `Aplicación`,
    card4: `Secado`,
    card5: `Presentación`,
    card6: `Ficha Técnica`,
    card7: `Ficha de seguridad`
}