import React from "react";
import lata from "../../img/lata2.png";
import "./styles.css";
import {variables as vars} from "../../utils/variables"

const BannerMain = () => {
    if (vars("countryAbbr") == "br") {
      return (  
        <React.Fragment>
          <a href={vars("mainBannerHref")}>
            <div className="row w-100 mx-0 banner-desktop" id="main-banner" style={{backgroundImage: "url("+process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/main_banner.jpg"+")"}}>
              <div className="container">
                <div className="row">
                  <div className="d-none d-md-flex col-md-7 offset-md-1 p-md-0 align-items-center justify-content-end">
                    <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                  </div>
                  <div className="col-md-4 text-center text-md-left">
                    <img src={lata} className="img-fluid" alt="Sparlack" id="lata" />
                  </div>
                  <div className="d-md-none col">
                    <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                  </div>
                </div>
              </div>
            {/* <div className="col p-0">
               <img src={banner} alt="banner" className="d-none d-md-block w-100"  />
              <img src={bannerMob} alt="banner" className="d-md-none img-fluid" /> 
            </div> */}
            </div>
          </a>
          <a href={vars("mainBannerHref")}>
            <div className="row w-100 mx-0 banner-mobile" id="main-banner" style={{backgroundImage: "url("+process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banners_main/main_banner_m.jpg"+")"}}>
              <div className="container">
                <div className="row">
                  <div className="d-none d-md-flex col-md-7 offset-md-1 p-md-0 align-items-center justify-content-end">
                    <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                  </div>
                  <div className="col-md-4 text-center text-md-left">
                    <img src={lata} className="img-fluid" alt="Sparlack" id="lata" />
                  </div>
                  <div className="d-md-none col">
                    <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                  </div>
                </div>
              </div>
              {/* <div className="col p-0">
                 <img src={banner} alt="banner" className="d-none d-md-block w-100"  />
                <img src={bannerMob} alt="banner" className="d-md-none img-fluid" /> 
              </div> */}
            </div>
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="row w-100 mx-0" id="main-banner-original">
            <div className="container">
              <div className="row">
                <div className="d-none d-md-flex col-md-7 offset-md-1 p-md-0 align-items-center justify-content-end">
                  <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                </div>
                <div className="col-md-4 text-center text-md-left">
                  <img src={lata} className="img-fluid" alt="Sparlack" id="lata" />
                </div>
                <div className="d-md-none col">
                  <h1>{vars("woodLives")}<br /> {vars("weTakeCare")}</h1>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
};

export default BannerMain;
