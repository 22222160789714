import React from 'react';
import facebook from "../img/facebook.png";
import instagram from "../img/instagram.png";
import youtube from "../img/youtube.png";
import {variables as vars} from "../utils/variables"

const Social = () => {

    const openFb = () => {
        window.open(vars("fbk"), "_blank");
      };
      const openIg = () => {
        window.open(vars("ig"), "_blank");
      };
      const openYt = () => {
        window.open(vars("youtubeLink"), "_blank");
      };
      
    return ( 
        <div className="row">
                    {vars("fbk") && 
                    <div className="col pr-0 text-center text-md-start">
                      <img
                        onClick={openFb}
                        className="img-fluid"
                        src={facebook}
                        alt="facebook"
                      />
                    </div>
                    }
                    {vars("ig") && 
                    <div className="col pr-0 text-center text-md-start">
                      <img
                        onClick={openIg}
                        className="img-fluid"
                        src={instagram}
                        alt="instagram"
                      />
                    </div>
                    }
                    <div className="col pr-0 text-center text-md-start">
                      <img
                        onClick={openYt}
                        className="img-fluid"
                        src={youtube}
                        alt="youtube"
                      />
                    </div>
                  </div>
     );
}
 
export default Social;