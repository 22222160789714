import React, { Fragment } from "react";
import {variables as vars} from "../../utils/variables"

const BannerText = () => {
  return (
    <div className="row px-md-5">
      <div className="col">
        <div className="row">
          <div className="col-md-6 pr-md-0">
            <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banner3a.jpg"} alt="banner" className="w-100" />
          </div>
          <div className="col-md-6  text-box d-flex align-items-center justify-content-center">
            <div className="text-container pt-2 px-2">
              <p className="font-bold">
                {vars("text1")}
              </p>
              <p className="font-bold">
              {vars("text2")}
                
              </p>

              <p className="font-bold">
              {vars("text3")}
                
              </p>

              <p className="font-bold">
              {vars("text4")}
                
              </p>

              <h5 className="mt-4 font-bold mb-3 mb-md-0">
              {vars("text5")}
                
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-md-none">
            <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banner3b.jpg"} alt="banner" className="w-100" />
          </div>
          <div className="col-md-6  d-flex align-items-center justify-content-center">
            <div className="text-container pt-2 px-4">
              <p className="font-bold">
              {vars("text6")}
                
              </p>

              <p className="font-bold">{vars("text7")}</p>
              <p className="font-bold">{vars("text8")}</p>
              <p className="font-bold">{vars("text9")}
                
              </p>
            </div>
          </div>
          <div className="d-none d-md-block col-md-6  pl-md-0">
            <img src={process.env.PUBLIC_URL + "/img/"+vars("countryAbbr")+"/banner3b.jpg"} alt="banner" className="w-100" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerText;
